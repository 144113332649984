import React, {
  Children,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react"
import RegisterProfileContainer from "containers/member/profile/registerProfileContainer"
import WhiteBoxContainer from "containers/member/profile/whiteBoxContainer"
import * as styles from "pages/member/profile/home.module.css"
import Logo from "components/tools/logo.js"
import api from "apis"

import { profiles, topics } from "utils/data.js"
import { useDispatch } from "react-redux"
import { getProfileAsync } from "store/modules/profileReducer"
import { navigate } from "gatsby"
import { emailReg } from "utils/memberUtils"

const ManageProfile = () => {
  const [state, setState] = useState(null)
  const [subject, setSubject] = useState(null)

  const [subjectArray, setSubjectArray] = useState([])
  const [photoArray, setPhotoArray] = useState([])
  const [subnumarray, setSubnumArray] = useState([])
  const isClicked = useRef()
  const clickedKey = useRef()
  const [T, setT] = useState(false)
  const turnT = () => setT(T => !T)
  const mount = useRef(false)

  const [profile, setProfile] = useState({
    profile: "",
    profileNum: 0,
    name: "",
    phoneNum: "",
    phoneAuth: "",
    email: "",
    subject: [],
    subjectPhoto: [],
    subnumarray: [],
  })

  useEffect(async () => {
    await checkingProfile()
    turnT()
  }, [])

  useEffect(() => {
    if (!mount.current) {
      mount.current = true
    } else {
      saveInfo_(
        "subject",
        subjectArray,
        "subjectPhoto",
        photoArray,
        "subnumarray",
        subnumarray
      )
    }
  }, [T])

  const setClickedKey = key => {
    clickedKey.current = key
    const clickedSubjectNum = subnumarray[clickedKey.current]
    return clickedSubjectNum
  }

  const setIsClicked = target => {
    const prevClicked = isClicked.current ?? target
    isClicked.current = target

    return prevClicked
  }

  const successSubject_ = (text, photo, subnum) => {
    if (text !== undefined) {
      if (subjectArray.length < 5 && clickedKey.current > subjectArray.length) {
        text === undefined
          ? setSubjectArray([...profile?.subject])
          : setSubjectArray([...profile?.subject, text])
        photo === undefined
          ? setPhotoArray([...profile?.subjectPhoto])
          : setPhotoArray([...profile?.subjectPhoto, photo])
        subnum === undefined
          ? setSubnumArray([...profile?.subnumarray])
          : setSubnumArray([...profile?.subnumarray, subnum])
      } else if (subjectArray.includes(text) === false) {
        subjectArray[clickedKey.current] = text
        photoArray[clickedKey.current] = photo
        subnumarray[clickedKey.current] = subnum
      } else if (subjectArray.includes(text)) {
        subjectArray.splice(clickedKey.current, 1)
        photoArray.splice(clickedKey.current, 1)
        subnumarray.splice(clickedKey.current, 1)
      }
    }
    turnT()
  }

  const checkingProfile = async () => {
    const k = await api.readProfile()

    let numarray = []
    let photo = []
    let subject = []

    k["topics"]
      .toString(2)
      .split("")
      .reverse()
      .map((elem, idx) => {
        if (parseInt(elem) === 1) return ++idx
      })
      .filter(elem => elem !== undefined)
      .map((v, idx) => {
        numarray.push(v)
        subject.push(topics.get(v).contents)
        photo.push(topics.get(v).img)
      })

    setProfile({
      ...profile,

      ["profile"]: profiles.get(k["icon_no"]),
      ["name"]: k["name"],
      ["phoneNum"]: k["phone"],
      ["email"]: k["email"],
      ["subject"]: subject,
      ["subjectPhoto"]: photo,
      ["subnumarray"]: numarray,
    })

    setSubjectArray([...subject])
    setPhotoArray([...photo])
    setSubnumArray([...numarray])
    return k
  }

  const openBox_ = (sub, check) => {
    setSubject(sub)
    setState(check)
  }

  const saveInfo_ = useCallback(
    (sub, info, sub1, info1, sub2, info2) => {
      setState(null)
      setProfile({ ...profile, [sub1]: info1, [sub]: info, [sub2]: info2 })
      console.log(profile)
    },
    [profile]
  )

  //핸드폰 인증하기
  const verifyNum_ = async () => {
    console.log(profile["phoneNum"])

    if (document.getElementsByName("phoneNum")[0].value === "") {
      document.getElementsByClassName("errMsg")[2].textContent =
        "필수 항목입니다."
      return
    }

    const result = await api.getMobileConfirm({
      body: { phone_number: profile["phoneNum"] },
    })

    if (result) phone = result

    console.log(phone)
  }

  const checkprofile_ = sub => {
    return profile[sub]
  }

  const dispatch = useDispatch()

  const save_ = async () => {
    const k = await api.readProfile()
    console.log(k)
    let numarray = []
    k["Topics"].map((v, idx) => numarray.push(v["topic_no"])) // "topics" -> "Topics"로 변경

    // console.log(await api.readProfile())

    const newArray = {
      icon: "",
      name: "",
      number: "",
      code: "",
      timestamp: "",
      email: "",
      topics: [],
    }

    //아이콘 변경시
    if (k["icon"] !== profile["profile"]) {
      newArray["icon"] = profile["profileNum"]
    }

    //이름 변경시
    if (k["name"] != profile["name"]) {
      newArray["name"] = profile["name"]
    }

    //핸드폰 번호 변경시
    if (k["phone"] != profile["phoneNum"]) {
      if (document.getElementsByName("phoneAuth")[0].value === "") {
        document.getElementsByClassName("errMsg")[3].textContent =
          "필수 항목입니다."
        return
      }

      const result = await api.mobileConfirm({
        body: {
          phone_uuid: phone["uuid"], // number -> phone_uuid로 수정
          phone_code: profile["phoneAuth"], // code -> phone_code로 수정
        },
      })

      if (result?.check) {
        alert("모바일 인증 성공")
        phone = result
        newArray["number"] = profile["phoneNum"]
        newArray["code"] = profile["phoneAuth"]
        newArray["timestamp"] = phone.timestamp
      } else {
        alert("인증번호를 정확하게 입력해 주세요.")
        return
      }
    }

    if (k["email"] != profile["email"]) {
      //이메일 변경시
      newArray["email"] = profile["email"]
    }

    //주제 변경시
    if (numarray != profile["subnumarray"]) {
      newArray["topics"] = profile["subnumarray"]
    }

    if (!emailReg(profile["email"])) {
      alert("올바른 이메일 주소를 입력해 주세요.")
      return
    }

    console.log(newArray)
    dispatch(getProfileAsync(newArray))

    navigate("/member/profile/home?option=revise")
  }
  let txt
  let txt2

  if (subject) {
    txt = subject.length === 2 ? subject[1] : subject[2]
    // 를 최대 5개까지 선택하시면, 피치서가가 스토리를 추천해 드릴게요.
  }
  console.log(subject)
  console.log(state)
  console.log(txt)

  return (
    <div
      className={styles.home}
      style={{
        minHeight:
          state &&
          (txt === "를 선택해 주세요." ||
            txt ===
              "를 최대 5개까지 선택하시면, 피치서가가 스토리를 추천해 드릴게요.")
            ? "1800px"
            : "900px",
      }}
    >
      <div className={`${styles.divparent} ${styles.divall}`}>
        <div className={styles.divlogo}>
          <Logo w={188} h={41} white={true} navCancel={true} />
        </div>
      </div>

      <div className={`${styles.divparent} ${styles.divparentcenter}`}>
        {state ? (
          <WhiteBoxContainer subject={subject}> {state} </WhiteBoxContainer>
        ) : (
          <RegisterProfileContainer
            title={"프로필 수정"}
            openBox_={openBox_}
            saveInfo_={saveInfo_}
            profile={profile}
            checkprofile_={checkprofile_}
            save_={save_}
            verifyNum_={verifyNum_}
            successSubject_={successSubject_}
            subjectArray={subjectArray}
            setIsClicked={setIsClicked}
            setClickedKey={setClickedKey}
          />
        )}
      </div>
    </div>
  )
}

export default ManageProfile
export let phone = {
  number: "",
  code: "",
  timestamp: "",
  check: false,
}
